<template>
  <bs-row class="mb-2">
    <bs-col style="background: #eee">
      <bs-row class="my-2">
        <!-- <bs-col>
          <tirscript3-date-picker
            minimumView="time"
            maximumView="years"
            initialView="days"
            :changeDateManual="true"
            dateFormat="DD.MM.YYYY HH:mm:ss"
            :asUTC="false"
            v-model="filter.DateFrom"
          ></tirscript3-date-picker>
        </bs-col>
        <bs-col>
          <tirscript3-date-picker
            v-model="filter.DateTo"
            minimumView="time"
            maximumView="years"
            initialView="days"
            :changeDateManual="true"
            dateFormat="DD.MM.YYYY HH:mm:ss"
            :asUTC="false"
          ></tirscript3-date-picker>
        </bs-col> -->
      </bs-row>
      <bs-row class="my-2">
        <bs-col v-if="methods">
          <tirscript3-dropdown
            placeholder="Контроллер/метод"
            :items="controllerMethods"
            @change="onChangeControllerMethods"
            autocomplete
            @changeAutocomlite="onChangeAutocomlite"
          >
          </tirscript3-dropdown>
        </bs-col>
        <bs-col v-if="logsType">
          <tirscript3-dropdown
            placeholder="Типы логов"
            :items="logsTypes"
            @change="onChangeLogsType"
          ></tirscript3-dropdown>
        </bs-col>
        <bs-col v-if="logsLevel">
          <tirscript3-dropdown
            placeholder="Уровень логов"
            :items="logsLevels"
            @change="onChangeLogsLevel"
          ></tirscript3-dropdown
        ></bs-col>
      </bs-row>
    </bs-col>
  </bs-row>
  <bs-row class="mb-2" v-if="addPanel">
    <bs-col style="background: #eee">
      <bs-row class="my-2">
        <bs-col class="min-width" v-if="url">
          <div class="ui-input mb-3">
            <input v-model="filter.Url" placeholder="Url..." tabindex="10" />
          </div>
        </bs-col>
        <bs-col class="min-width">
          <div class="ui-input mb-3">
            <input
              placeholder="SessionToken..."
              tabindex="10"
              v-model="filter.SessionToken"
            />
          </div>
        </bs-col>
        <bs-col class="min-width">
          <div class="ui-input mb-3">
            <input v-model="filter.Ip" placeholder="Ip..." tabindex="10" />
          </div>
        </bs-col>
        <bs-col class="min-width">
          <div class="ui-input mb-3">
            <input
              v-model="filter.UserId"
              placeholder="UserId..."
              tabindex="10"
            />
          </div>
        </bs-col>
        <bs-col class="min-width">
          <div class="ui-input mb-3">
            <input
              v-model="filter.ConnectionId"
              placeholder="ConnectionId..."
              tabindex="10"
            />
          </div>
        </bs-col>
        <bs-col class="min-width" v-if="requestId">
          <div class="ui-input mb-3">
            <input
              v-model="filter.RequestId"
              placeholder="RequestId..."
              tabindex="10"
            />
          </div>
        </bs-col>
        <bs-col class="col-auto">
          <tirscript3-button
            active
            style="max-width: 20px; min-width: 20px"
            @onClick="clearFilter"
          >
            X
          </tirscript3-button></bs-col
        >
      </bs-row>
    </bs-col>
  </bs-row>
</template>
<script lang="ts">
import { Options, Vue, Prop, Watch } from "vue-property-decorator";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import FilterModel from "@views/page/components/filter-model";
import GetEndpointsByNameResponse from "@dataSource/LoggerMicroService/Services/Models/Response/EndpointService/GetEndpointsByNameResponse";
@Options<ViewFilter>({
  name: "ViewFilter",
})
export default class ViewFilter extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;
  @Prop({ default: true }) logsType: Boolean;
  @Prop({ default: true }) logsLevel: Boolean;
  @Prop({ default: true }) requestId: Boolean;
  @Prop({ default: true }) url: Boolean;
  @Prop({ default: true }) addPanel: Boolean;
  @Prop({ default: false }) methods: Boolean;

  @Watch("filter", { deep: true })
  onFilter(value) {
    if (this.logsLevels && this.logsLevels.length > 0) {
      const logsLevels = this.filter.LogLevel ? this.filter.LogLevel : null;
      this.logsLevels.forEach(
        (el) => (el.Selected = (el.Value as DefaultModel).id == logsLevels)
      );
    }
    if (this.logsTypes && this.logsTypes.length > 0) {
      const logType = this.filter.LogType ? this.filter.LogType : null;
      this.logsTypes.forEach(
        (el) => (el.Selected = (el.Value as DefaultModel).id == logType)
      );
    }
    if (this.controllerMethods && this.controllerMethods.length > 0) {
      const EndpointId = this.filter.EndpointId ? this.filter.EndpointId : null;
      this.controllerMethods.forEach(
        (el) => (el.Selected = (el.Value as DefaultModel).id == EndpointId)
      );
    }
    this.$emit("update:filter", this.filter);
  }

  controllerMethods: ListViewItem<DefaultModel>[] = [];
  logsLevels: ListViewItem<DefaultModel>[] = [];
  logsTypes: ListViewItem<DefaultModel>[] = [];
  searchEndpoint = "";
  mounted() {
    console.log(`requestId`, this.requestId);
    this.getlogsLevels();
    this.getlogsTypes();
    this.getControllerMethods();
  }
  onclickFilterChange() {
    // ловим событие нажатия кнопки отфильтровать
    // debugger;
    // this.$emit("update:filter", this.filterModel);
  }
  onChangeLogsType(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.LogType = id;
  }
  onChangeLogsLevel(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.LogLevel = id;
  }
  onChangeControllerMethods(items: ListViewItem<DefaultModel>[]) {
    let id = (
      items.filter((el) => el.Selected === true)[0].Value as DefaultModel
    ).id;
    if (id == -1) id = null;
    this.filter.EndpointId = id;
  }

  getlogsLevels() {
    this.logsLevels = [
      new ListViewItem(new DefaultModel(null, "All logs level"), true, false),
      new ListViewItem(new DefaultModel(0, "Debug"), false, false),
      new ListViewItem(new DefaultModel(1, "Trace"), false, false),
      new ListViewItem(new DefaultModel(3, "Info"), false, false),
      new ListViewItem(new DefaultModel(4, "Warn"), false, false),
      new ListViewItem(new DefaultModel(5, "Error"), false, false),
    ];
  }
  onChangeAutocomlite(value: string) {
    this.searchEndpoint = value;
    this.getControllerMethods();
  }
  async getControllerMethods() {
    if (!this.methods) {
      return;
    }
    this.controllerMethods = [];

    this.controllerMethods.push(
      new ListViewItem(
        new DefaultModel(null, "Любой контроллер/метод"),
        true,
        false
      )
    );
    let res: GetEndpointsByNameResponse = null;
    try {
      res = await this.$api.EndpointService.getEndpointsAsync({
        Page: { Skip: 0, Take: 30 },
        Search: this.searchEndpoint,
      });
    } catch (error) {
      return;
    }

    res.Items.forEach((el) => {
      this.controllerMethods.push(
        new ListViewItem(
          new DefaultModel(el.Id, `${el.Class} / ${el.Method}`),
          false,
          false
        )
      );
    });
  }

  getlogsTypes() {
    this.logsTypes = [
      new ListViewItem(new DefaultModel(null, "All logs types"), true, false),
    ];
    for (let index = 0; index < 11; index++) {
      this.logsTypes.push(
        new ListViewItem(
          new DefaultModel(index, this.$filters.LogType(index).toString()),
          false,
          false
        )
      );
    }
  }
  clearFilter() {
    this.filter.Url = "";
    this.filter.LogType = null;
    this.filter.LogLevel = null;
    this.filter.Url = "";
    this.filter.SessionToken = "";
    this.filter.Ip = "";
    this.filter.UserId = "";
    this.filter.ConnectionId = "";
    this.filter.RequestId = "";
    this.filter.source = null;
  }
}
</script>

<style lang="less">
.view-filter {
}
.ui-date-picker {
  .input-group {
    min-width: 174px;
  }
}
</style>